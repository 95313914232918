var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Header", { attrs: { title: "投资详情", back: true } }),
      _c("div", { staticClass: "top-box flex-box" }, [
        _c("div", [
          _c("div", [_vm._v("总金额($)")]),
          _c("div", { staticClass: "theme-color" }, [
            _vm._v(_vm._s(_vm._f("formatFloat")(_vm.total))),
          ]),
        ]),
        _c("div", [
          _c("div", [_vm._v("累计收益($)")]),
          _c("div", { staticClass: "theme-color" }, [
            _vm._v(_vm._s(_vm._f("formatFloat")(_vm.income))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "box-items" }, [
        _c("div", { staticClass: "automatic-box flex-box" }, [
          _c("div", [_vm._v("到期后自动转到下一期")]),
          _c(
            "div",
            [
              _c("el-switch", {
                attrs: { "active-color": "#002D91", disabled: _vm.day == "-" },
                on: { change: _vm.changeAutomatic },
                model: {
                  value: _vm.automatic,
                  callback: function ($$v) {
                    _vm.automatic = $$v
                  },
                  expression: "automatic",
                },
              }),
            ],
            1
          ),
        ]),
        _vm.automatic
          ? _c("div", { staticClass: "automatic-tips-min" }, [
              _vm._v(
                "自动续投开启，本金及收益将继续封闭至下一周期，收益不间断，到期日前一天早9点前可更改"
              ),
            ])
          : _c("div", { staticClass: "automatic-tips-min" }, [
              _vm._v(
                "自动续投关闭，到期后本金及收益将在下一个交易日，自动转出至现金余额，可在“持有”页面查看。"
              ),
            ]),
      ]),
      _c("div", { staticClass: "box-items" }, [
        _c("div", { staticClass: "automatic-box flex-box" }, [
          _c("div", [_vm._v("距离到期")]),
          _c("div", { staticClass: "theme-color" }, [
            _vm._v(" " + _vm._s(_vm.day) + "天 "),
          ]),
        ]),
        _c("div", { staticClass: "automatic-tips" }, [
          _c("div", { staticClass: "automatic-box flex-box" }, [
            _c("div", [_vm._v("购买金额")]),
            _c("div", { staticClass: "theme-color" }, [
              _vm._v(" $" + _vm._s(_vm.money) + " "),
            ]),
          ]),
          _c("div", { staticClass: "automatic-box flex-box" }, [
            _c("div", [_vm._v("起息日期")]),
            _c("div", { staticClass: "theme-color" }, [
              _vm._v(" " + _vm._s(_vm.startDate) + " "),
            ]),
          ]),
          _c("div", { staticClass: "automatic-box flex-box" }, [
            _c("div", [_vm._v("到期日期")]),
            _c("div", { staticClass: "theme-color" }, [
              _vm._v(" " + _vm._s(_vm.endDate) + " "),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "box-items" }, [
        _c(
          "div",
          {
            staticClass: "automatic-box flex-box",
            staticStyle: { "margin-bottom": "0px" },
            on: { click: _vm.xy },
          },
          [
            _c("div", { staticClass: "theme-color" }, [_vm._v("查看协议")]),
            _vm._m(0),
          ]
        ),
      ]),
      _vm.day != "-"
        ? _c("div", { staticClass: "finacing-footer" }, [
            _c(
              "div",
              { staticClass: "add-btn theme-bgcolor", on: { click: _vm.lx } },
              [_vm._v("提前转出")]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "theme-color" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }