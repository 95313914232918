<template>
  <div>
    <Header :title="'投资详情'" :back="true"/>
    <div class="top-box flex-box">
      <div>
        <div>总金额($)</div>
        <div class="theme-color">{{total|formatFloat}}</div>
      </div>
      <div>
        <div>累计收益($)</div>
        <div class="theme-color">{{income|formatFloat}}</div>
      </div>
    </div>
    <div class="box-items">
      <div class="automatic-box flex-box">
        <div>到期后自动转到下一期</div>
        <div>
          <!-- disabled -->
          <el-switch
            v-model="automatic"
            active-color="#002D91"
            :disabled="day=='-'"
            @change="changeAutomatic"
            >
          </el-switch>
        </div>
      </div>
      <div v-if="automatic" class="automatic-tips-min">自动续投开启，本金及收益将继续封闭至下一周期，收益不间断，到期日前一天早9点前可更改</div>
      <div v-else class="automatic-tips-min">自动续投关闭，到期后本金及收益将在下一个交易日，自动转出至现金余额，可在“持有”页面查看。</div>
    </div>
    <div class="box-items">
      <div class="automatic-box flex-box">
        <div>距离到期</div>
        <div class="theme-color">
          {{day}}天
        </div>
      </div>
      <div class="automatic-tips">
          <div class="automatic-box flex-box">
            <div>购买金额</div>
            <div class="theme-color">
              ${{money}}
            </div>
          </div>
          <div class="automatic-box flex-box">
            <div>起息日期</div>
            <div class="theme-color">
              {{startDate}}
            </div>
          </div>
          <div class="automatic-box flex-box">
            <div>到期日期</div>
            <div class="theme-color">
              {{endDate}}
            </div>
          </div>
      </div>
    </div>
    <div class="box-items">
      <div class="automatic-box flex-box"  style="margin-bottom:0px" @click="xy">
        <div class="theme-color">查看协议</div>
        <div class="theme-color">
         <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
    <div class="finacing-footer" v-if="day!='-'">
      <div class="add-btn theme-bgcolor" @click="lx">提前转出</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/Header'
export default {
  components: {
    Header
  },
  data(){
    return{
      automatic:false,
      day:'',
      money:'',
      startDate:'',
      endDate:'',
      total:'',
      income:''
    }
  },
  created(){
    this.getData()

  },
  methods:{
    getData(){
      let params={
        id:this.$route.query.key
      }
      this.$store
        .dispatch('stages/buyDetail',{params:params})
        .then((res) => {
          this.day = res.investment.remainDays
          this.automatic =res.investment.auto
          
          this.money= res.investment.money
          this.startDate= res.investment.returndate
          this.endDate= res.investment.enddate
          this.total =  res.investment.worth
          this.income =  res.investment.income
        })
    },
    lx(){
      this.$router.push({path:'before',query:{key:this.$route.query.key}})
    },
    // 查看协议
    xy(){
      this.$router.push({path:'subscription'})
    },
    changeAutomatic(val){
      let params={
        id:this.$route.query.key,
        auto:val
      }
      this.$store
        .dispatch('stages/changestatus',params)
        .then((res) => {
          console.log(res)
         
        })
      console.log(val)

    }
  }
}
</script>
<style lang="scss" scoped>
.automatic-box{
  justify-content: space-between;
  align-self:center;
  margin-bottom:2.5%
}
.box-items{
  margin-top:2.5%
}
.automatic-tips{
  margin:10px 5px;
  border-top:1px solid #eee;
  padding-top:10px;
  color: #464646;
  line-height: 1.5;
}
.automatic-tips-min{
  margin:10px 5px;
  border-top:1px solid #eee;
  padding-top:10px;
  color: #464646;
  line-height: 1.5;
  font-size: .9rem;
}
.top-box{
  background: #fff;
  padding: 5%;
  justify-content: space-around;
  text-align: center;
  >div{
     width: 50%;
     font-size: 2rem;
      >:first-child{
        font-size: .9rem;
        color: #464646;
      }
  }
  >:first-child{
    border-right:1px solid #eee
  }
}
.finacing-footer{
  position: fixed;
  bottom:0px;
  width: 95%;
  padding: 2.5%;
  .add-btn{
    border-radius:8px;
    width: 100%;
    text-align: center;
    line-height: 2.5;
    color: #fff;
  }
}
</style>